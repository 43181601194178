<template>
  <div>
    <div class="header_top noSel  hidden-xs-only ">
      <div class="box flex a_c j_s">
        <div>咨询电话：025-86667555</div>
        <ul class="flex a_c">
          <li @click="toHelp">加入收藏</li>
          <li @click="toMicro">官方微博</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import storage from "@/utils/storage";
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  computed: {},
  components: {},
  props: {
    msg: String,
  },
  methods: {
    //跳转至官方微博
    toMicro() {
      window.open(
        "https://weibo.com/u/7457296330?refer_flag=1001030103_",
        "_blank"
      );
      // window.open("www.baidu.com");
    },
    //加入收藏
    toCollect() {
      console.log("开发中。。。");
    },
    //获取用户登录状态
    getUserInfo() {
      var userInfo = storage.get("userInfo", true);
      if (userInfo) {
        var timeNow = new Date().getTime();
        var timeEnd = userInfo.time;
        var timeFall = parseInt(timeNow / 1000) - timeEnd;
        console.log("时间差=", timeFall, "(秒)");
        if (timeFall < 1296) {
          this.isUserLoged = true;
        }
      }
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
    },
  },
};
</script>

<style scoped>
/* 头部上方 */
.header_top {
  height: 43px;
  background-color: #424242;
}
.header_top .box {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  font-size: 14px;
  color: #FFFFFF;;
}
.header_top .box li {
  margin-left: 20px;
  cursor: pointer;
}
.header_top .box span {
  margin-left: 10px;
}
.header_top .box img {
  width: 26px;
  height: 26px;
}
/* 购物车 */
.shopCarIcon {
  position: relative;
}
.shopCarBox {
  position: absolute;
  top: 36px;
  left: 26px;
  width: 280px;
  min-height: 600px;
  background: #ffffff;
  box-shadow: 0px 3px 5px 1px rgba(0, 0, 0, 0.4);
  z-index: 999999;
  cursor: auto;
}
.shopCarBox .header {
  height: 33px;
  border-bottom: 1px solid #dcdcdc;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 33px;
  box-sizing: border-box;
  padding: 0 7px 0 0;
}
.shopCarBox .header img {
  width: 11px;
  height: 11px;
  cursor: pointer;
}
.shopCarList {
  height: 495px;
  overflow: auto;
}
.shopCarPrice {
  height: 22px;
  border-top: 1px solid #dcdcdc;
  border-bottom: 1px solid #dcdcdc;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  text-align: end;
  padding-right: 10px;
}
.shopCarOper {
  height: 47px;
  justify-content: flex-end !important;
}

.shopCarOper .preview {
  background: #7d7c7c;
  height: 27px;
  padding: 0 10px;
  line-height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.shopCarOper .settle {
  background: #000000;
  height: 27px;
  padding: 0 10px;
  line-height: 27px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 0 10px;
  cursor: pointer;
}
.shopCarOper .settle img {
  width: 14px;
  height: 12px;
  padding-left: 5px;
}
.shopCarOper .settle span {
  line-height: 27px;
}

/* 购物车商品列表 */
.shopCarList .shopListBox {
  box-sizing: border-box;
  padding: 0 10px;
}
.shopCarList .shopListBox > div {
  border-bottom: 1px solid #dcdcdc;
  padding: 20px 0;
}
.shopCarList .shopListBox > div:last-child {
  border-bottom: none;
}
.shopCarList .shopListBox > div img {
  width: 112px;
  height: 120px;
}
.shopListBox .text {
  width: 150px;
  padding-left: 19px;
  box-sizing: border-box;
}
.shopListBox .text > div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shopListBox .nameText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.shopListBox .nameEngText {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.shopListBox .typeText {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.shopListBox .volumeText {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
.shopListBox .priceText {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
}
.shopListBox .numText {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 16px;
}
</style>
