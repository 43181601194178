<template>
  <div>
    <Top></Top>
  </div>
</template>

<script>
import Top from './header_top.vue'
export default {
  name: "",
  props: {
    msg: String,
  },
  components:{
     Top,
  }
};
</script>

<style scoped>

</style>
