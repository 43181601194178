<template>
  <div>
    <Header></Header>
    <!-- 导 航 1-->
    <div class="header_nav noSel hidden-xs-only flex a_c">
      <img
        src="https://static.5t1800.com/s3/2023/05/08/64585e19b63f1.png"
        alt=""
      />
      <ul class="box flex a_c">
        <li
          v-for="(item, i) in navList"
          :key="i"
          :class="{ on: $store.state.routerIndex == i }"
          @click="choseTab(i)"
        >
          {{ item.name }}
        </li>
      </ul>
    </div>
    <div class="header_navPhone noSel hidden-sm-and-up flex a_c j_s">
      <img
        class="logo"
        src="https://static.5t1800.com/s3/2023/05/08/64585e19b63f1.png"
        alt=""
      />
      <img
        @click="showPopup"
        class="icon"
        src="https://static.5t1800.com/s3/2023/05/08/6458a37bcd677.png"
        alt=""
      />
      <van-popup v-model="show" position="right" :style="{ height: '100%' }">
        <div class="popBox">
          <img
            class="popLogo"
            src="https://static.5t1800.com/s3/2023/05/08/64585e19b63f1.png"
            alt=""
          />
          <div class="boxPhone">
            <div
              v-for="(item, i) in navList"
              :key="i"
              :class="{ on: $store.state.routerIndex == i }"
              @click="choseTab(i)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
      </van-popup>
    </div>
    <div class="hidden-sm-and-up" style="height: 46px"></div>
    <router-view></router-view>
    <Footer></Footer>
  </div>
</template>

<script>
//引入头部组件
import Vue from "vue";
import { Popup } from "vant";
Vue.use(Popup);
import Header from "../../components/header.vue";
import Footer from "../../components/footer.vue";
export default {
  data() {
    return {
      navList: [
        {
          name: "首页",
          routerName: "home",
        },
        {
          name: "品牌历程",
          routerName: "brandHistory",
        },
        {
          name: "皮肤管理",
          routerName: "skinManagement",
        },
        {
          name: "居家产品",
          routerName: "homeProducts",
        },
        {
          name: "加盟合作",
          routerName: "franchiseCooperation",
        },
        {
          name: "最新动态",
          routerName: "news",
        },
      ],
      show: false,
    };
  },
  created() {},
  computed: {
    // choseNav:  ()=> {
    //   var choseNav = this.$store.state.routerIndex
    //   return choseNav
    // },
  },
  components: {
    Header,
    Footer,
  },
  methods: {
    // 切换tab
    choseTab(i) {
      var value = this.navList[i].routerName;
      this.routerTo(value);
      // if (i == 1) {
      //   this.$store.commit("saveTypeIndex", 2);
      //   var value2 = this.navList[2].routerName;
      //   try {
      //     this.$router.push({
      //       name: value2,
      //       params: {
      //         choseIndex: "6"
      //       }
      //     });
      //   } catch {
      //      window.location.reload()
      //   }
      // } else if (i == 4) {
      //   window.open("http://kf.5t1800.com/s/552701p5k", "_blank");
      // } else {
      //   this.$store.commit("saveTypeIndex", i);
      //   var value = this.navList[i].routerName;
      //   this.routerTo(value);
      // }
    },
    //二级路由跳转
    routerTo(value) {
      this.$router.push({ name: value });
      this.show = false
    },
    //移动端展示弹出层
    showPopup() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
/* 头部下方导航 */
.header_nav {
  max-width: 1200px;
  margin: 0 auto;
  height: 82px;
  background: #ffffff;
}
.header_nav .box {
  height: 100%;
  margin-left: 40px;
  font-size: 22px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #424242;
}
.header_nav .box li {
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
  padding: 0 18px;
  line-height: 82px;
  margin-right: 25px;
}
.header_nav .box .on {
  color: #f19dad;
}
img {
  width: 280px;
  height: 45px;
}

.header_navPhone {
  box-sizing: border-box;
  height: 46px;
  padding: 0 8px;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 99999;
}
.header_navPhone .logo {
  width: 140px;
  height: 23px;
}
.header_navPhone .icon {
  width: 32px;
  height: 30px;
}

.popLogo {
  width: 140px;
  height: 23px;
  margin: 0 auto;
}
.popBox {
  padding:16px 8px;
}
.popBox .boxPhone {
  margin-top:20px;
  height: 100%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #424242;
}
.popBox .boxPhone div {
  cursor: pointer;
  box-sizing: border-box;
  height: 100%;
  line-height: 30px;
  list-style: none;
}
.popBox .boxPhone .on {
  color: #f19dad;
}
.popBox .boxPhone {
}
</style>

